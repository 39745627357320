// @flow strict
import React from 'react';
import styled from '@emotion/styled';

const ThanksPage = () => (
  <Root>
    <div>
      お問い合わせ、ありがとうございました。
      <br />
      <br />
      お問い合わせいただきましたら内容を確認した後、必ずご連絡させていただきます。
      <br />
      2～3日経っても返信がない場合は、再度ご連絡お願いいたします。
    </div>
    <Signature>チワワブリーダー リトルメイ</Signature>
  </Root>
);

const Root = styled.div((props) => ({
  margin: '4rem 1rem',
  textAlign: 'left',
  [props.theme.mediaQuery.minTablet]: {
    textAlign: 'center',
  },
}));

const Signature = styled.div({
  marginTop: '2rem',
  textAlign: 'right',
});

export default ThanksPage;
